<template>
  <div id="forecast">
    <div class="Forecast_name" v-show="show && currentPanel != ''">
      <div class="name">{{ currentPanel }}</div>
    </div>
    <div :class="this.scrollShow ? 'histogram' : 'histogram_versions'" v-show="show" @scroll="scrollEvent($event)">
      <div class="echart" style="" id="mychart"></div>
      <!-- 遮罩 -->
      <div class="shade" id="shade">
        <img
          :src="
            showShade == 0
              ? ' https://newstore.vynior.com/Frame%201280%20%281%29.png'
              : showShade == 1
              ? 'https://newstore.vynior.com/Frame%201281%20%281%29.png'
              : showShade == 2
              ? 'https://newstore.vynior.com/Frame%201282%20%281%29.png'
              : ''
          "
          alt="" />
        <div class="footer"></div>
      </div>
      <div class="Four_wire" id="Line" v-show="showLine">
        <div class="wire1" id="Line1" :style="`top:${one1}`">
          <div class="bodersone">
            <img style="width: 9.5px; height: 11px; margin: 0 6px" src="https://newstore.vynior.com/Group%2033595.png" alt="" />
            <span class="text" style="">P20:{{ line1.toFixed(2) }} </span>
          </div>
          <div class="dashed"></div>
        </div>
        <div class="wire2" id="Line2" :style="`top:${one2}`">
          <div class="boderstwo">
            <img style="width: 9.5px; height: 11px; margin: 0 6px" src="https://newstore.vynior.com/Group%2033595.png" alt="" />
            <span class="text" style="">P40:{{ line2.toFixed(2) }} </span>
          </div>
          <div class="dashed"></div>
        </div>
        <div class="wire3" id="Line3" :style="`top:${one3}`">
          <div class="bodersthree">
            <img style="width: 9.5px; height: 11px; margin: 0 6px" src="https://newstore.vynior.com/Group%2033595.png" alt="" />
            <span class="text" style="">P60:{{ line3.toFixed(2) }} </span>
          </div>
          <div class="dashed"></div>
        </div>
        <div class="wire4" id="Line4" :style="`top:${one4}`">
          <div class="bodersfour">
            <img style="width: 9.5px; height: 11px; margin: 0 6px" src="https://newstore.vynior.com/Group%2033595.png" alt="" />
            <span class="text" style="">P80:{{ line4.toFixed(2) }} </span>
          </div>
          <div class="dashed"></div>
        </div>
      </div>
      <div class="box_data">
        <div class="title_box" style="">
          <div class="data_title_header">
            <div class="data_title" v-for="(item, index) in likeProductList" :key="index" :for="index" v-show="index < 30">
              <div class="data_titles">
                <el-tooltip :open-delay="500" class="item" effect="dark" placement="top" popper-class="back_ground">
                  <div slot="content" style="line-height: 18px">
                    <p>{{ item.program }}</p>
                    <p>{{ item.product }}</p>
                  </div>
                  <p>
                    {{ item.program }} <br />
                    {{ item.product }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <div class="bycial">
          <div class="namelist" ref="leftForm" @scroll="scrollLeft()" id="item">
            <div
              v-for="(item, index) in likeNameList"
              :key="index"
              @mouseleave="hoverPanel = ''"
              @mouseenter="hoverPanel = item"
              @click="assignmentEcharts(index)"
              :class="currentPanel == item ? 'item_title_versions' : hoverPanel == item ? 'item_title_hover' : 'item_title'">
              <el-tooltip :open-delay="500" class="item" effect="dark" :content="item" placement="top" popper-class="back_ground">
                <div>
                  {{ item }}
                </div>
              </el-tooltip>
            </div>
          </div>
          <div class="box_flex_num" ref="rightForm" @scroll="scrollRight()">
            <div
              class="priduct_num_data"
              @mouseleave="hoverPanel = ''"
              @mouseenter="hoverPanel = likeNameList[index]"
              v-for="(item, index) in forcast_data"
              :key="index"
              @click="assignmentEcharts(index)"
              :class="currentPanel == likeNameList[index] ? 'BGcolor' : hoverPanel == likeNameList[index] ? 'BGcolor_hover' : 'priduct_num_data'">
              <div class="item-right">
                <span class="item" v-for="(data, indexs) in item" :key="indexs">{{ Number(data).toFixed(2) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box_none" v-show="show == false">
      <!-- 柱状图 -->
      <div class="none_echart">
        <div class="echart_num3">
          <span>5</span>
          <img src="https://newstore.vynior.com/Line%20118%402x.png" alt="" />
        </div>

        <div class="echart_num3">
          <span>4</span>
          <img src="https://newstore.vynior.com/Line%20118%402x.png" alt="" />
        </div>

        <div class="echart_num3">
          <span>3</span>
          <img src="https://newstore.vynior.com/Line%20118%402x.png" alt="" />
        </div>

        <div class="echart_num3">
          <span>2</span>
          <img src="https://newstore.vynior.com/Line%20118%402x.png" alt="" />
        </div>

        <div class="echart_num3">
          <span>1</span>
          <img src="https://newstore.vynior.com/Line%20118%402x.png" alt="" />
        </div>

        <div class="echart_num4">
          <span>0</span>
          <div class="spanss"></div>
        </div>

        <!-- 柱子 -->
        <div class="echart_ZZ">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>

        <div class="echart_ZZ1">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ2">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ3">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ4">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ5">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ6">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ7">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ8">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ9">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ10">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ11">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ12">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ13">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ14">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
      </div>

      <!-- 感官属性 -->
      <div class="none_valG">
        <!-- 横排属性名栏 -->
        <div class="valG_lan">
          <span class="span">-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span class="span2">-- --</span>
        </div>

        <!-- 竖排属性栏 -->
        <div class="valG_lie">
          <!-- 默认显示 -->
          <ul v-show="likeNameList.length == 0">
            <li>--</li>
            <li>--</li>
            <li>--</li>
            <li>--</li>
            <li>--</li>
            <li>--</li>
            <li>--</li>
            <li>--</li>
          </ul>

          <!-- 点击产品名 显示 -->
          <div class="valG_lie_name" v-show="likeNameList.length != 0">
            <p class="valG_lie_names" v-for="(item, index) in likeNameList" :key="index">
              {{ item }}
            </p>
          </div>
        </div>

        <!-- 暂无数据 -->
        <div class="noned">
          <img src="https://newstore.vynior.com/Group%2033628%402x.png" alt="" />
          <p>{{ $t(`message.History.NoData`) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      show: false, // 是否显示
      scrollShow: false, // 滚动条显示权限
      showShade: 0,
      showLine: false, // 控制四分线
      likeProductList: [],
      currentIndex: 0,
      currentPanel: '', // 点击选择
      hoverPanel: '', // 移动选择
      likeNameList: [],
      likeNameList_en: [], //英文名字
      forcast_data: [],
      showData: [],
      minScore: 1,
      maxScore: 5,
      // 四条线的值
      line1: 0,
      line2: 0,
      line3: 0,
      line4: 0,
      // 四条线的高度
      one1: '',
      one2: '',
      one3: '',
      one4: '',
      four_percent: [
        [3.74, 3.83, 3.94, 4.03],
        [3.82, 3.95, 4, 4.1],
        [3.95, 4.04, 4.1, 4.18],
        [3.93, 4.04, 4.14, 4.2],
        [3.95, 4.09, 4.16, 4.21],
        [3.77, 3.93, 4, 4.1],
        [2.95, 3.04, 3.1, 3.48],
        [3.74, 3.8, 3.86, 3.9],
        [3.97, 4.11, 4.2, 4.32],
        [3.88, 4, 4.12, 4.18],
        [3.96, 4.06, 4.13, 4.23],
        [3.68, 3.85, 3.92, 3.99],
        [3.76, 3.89, 3.96, 4.03],
        [3.74, 3.9, 3.99, 4.13],
        [3.8, 3.89, 3.95, 4],
        [3.97, 4.11, 4.17, 4.23],
        [3.57, 3.68, 3.74, 3.82],
        [2.66, 2.7, 2.74, 2.77],
        [2.77, 2.81, 2.85, 2.87],
        [2.5, 2.57, 2.63, 2.66],
        [3.85, 3.89, 3.91, 3.93],
        [4, 4.07, 4.09, 4.12],
        [3.78, 3.88, 3.93, 3.96],
        [3.54, 3.69, 3.79, 3.82],
      ],
    };
  },
  watch: {
    showLine(newValue) {
      if (newValue != this.$store.state.showLine) this.$store.commit('$_changeShowLine', newValue);
    },
    '$store.state.showLine': {
      handler(newValue) {
        this.showLine = newValue;
      },
    },
    '$store.state.likeProductList': {
      handler() {
        if (this.$store.state.likeProductList.length != 0) {
          this.likeProductList = this.$store.state.likeProductList;
        } else {
          this.likeProductList = [];
          this.showLine = false;
        }
      },
      deep: true,
    },
    '$store.state.likeNameList': {
      handler() {
        if (this.$store.state.likeNameList.length != 0) {
          this.likeNameList = this.$store.state.lang == 'cn' ? this.$store.state.likeNameList : this.$store.state.likeNameList_en;
        } else {
          this.likeNameList = [];
        }
      },
      deep: true,
    },
    '$store.state.forcast_data': {
      handler() {
        if (this.$store.state.forcast_data.length != 0) {
          this.forcast_data = this.$store.state.forcast_data;
          this.show = true;
          if (this.forcast_data[0].length > 15) {
            this.scrollShow = true;
          }
          this.assignmentEcharts(this.currentIndex);
          this.initEcharts();
        } else {
          this.forcast_data = [];
          this.showData = [];
          this.showLine = false;
          this.showShade = 0;
          this.minScore = 1;
          this.maxScore = 5;
          this.show = false;
          this.scrollShow = false;
          this.currentIndex = 0;
          this.currentPanel = '';
          this.hoverPanel = '';
        }
      },
      deep: true,
    },
    '$store.state.lang': {
      handler() {
        if (this.forcast_data.length !== 0) {
          this.assignmentEcharts(this.currentIndex);
        }
        if (this.$store.state.lang == 'cn') {
          this.likeNameList = this.$store.state.likeNameList;
        } else {
          console.log('英文');
          this.likeNameList = this.$store.state.likeNameList_en;
        }
      },
    },
  },
  mounted() {
    this.$EventBus.$on('DoSeachernum', (e) => {
      if (e == 1) {
        this.one1 = '35%';
        this.one2 = '31.25%';
        this.one3 = '26.5%';
        this.one4 = '22%';
        this.line1 = 3.57;
        this.line2 = 3.78;
        this.line3 = 3.93;
        this.line4 = 4.11;
        //  this.currentIndex[]
        console.log('点击ALL SPF');
      }
      if (e == 2) {
        this.one1 = '35.25%';
        this.one2 = '31.25%';
        this.one3 = '26.5%';
        this.one4 = '22%';
        this.line1 = 3.64;
        this.line2 = 3.78;
        this.line3 = 3.97;
        this.line4 = 4.12;
        console.log('点击SPF≥50F');
      }
      if (e == 3) {
        this.one1 = '36%';
        this.one2 = '31.25%';
        this.one3 = '27%';
        this.one4 = '26%';
        this.line1 = 3.43;
        this.line2 = 3.79;
        this.line3 = 3.91;
        this.line4 = 3.94;
        console.log('点击SPF<50F');
      }
      if (e == 4) {
        this.one1 = '37%';
        this.one2 = '31.25%';
        this.one3 = '28.25%';
        this.one4 = '26.5%';
        this.line1 = 3.5;
        this.line2 = 3.59;
        this.line3 = 3.77;
        this.line4 = 3.9;
        console.log('点击物理防晒');
      }
      if (e == 5) {
        this.one1 = '35.25%';
        this.one2 = '27%';
        this.one3 = '26%';
        this.one4 = '23.25%';
        this.line1 = 3.66;
        this.line2 = 3.82;
        this.line3 = 3.94;
        this.line4 = 4.13;
        console.log('点击化学防晒');
      }
      if (e == 0) {
        this.one1 = '32%';
        this.one2 = '28%';
        this.one3 = '25%';
        this.one4 = '22%';
        this.line1 = 3.73;
        this.line2 = 3.91;
        this.line3 = 4.03;
        this.line4 = 4.14;
        console.log('点击雾化结合');
      }
    });
    this.showLine = this.$store.state.showLine;
    if (this.$store.state.likeNameList.length != 0) {
      this.likeNameList = this.$store.state.likeNameList;
      this.likeNameList_en = this.$store.state.likeNameList_en;
    }
    if (this.$store.state.likeProductList.length != 0) {
      this.likeProductList = this.$store.state.likeProductList;
    }
    if (this.$store.state.forcast_data.length != 0) {
      this.forcast_data = this.$store.state.forcast_data;
      this.show = true;
      this.assignmentEcharts(this.currentIndex);
      this.initEcharts();
    }
    if (this.$store.state.lang == 'cn') {
      this.likeNameList = this.$store.state.likeNameList;
    } else {
      console.log('英文');
      this.likeNameList = this.$store.state.likeNameList_en;
    }
  },
  methods: {
    // 柱状图
    initEcharts() {
      // 基本柱状图
      const option = {
        color: '#29A1BB',
        xAxis: {
          type: 'category',
          data: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          yMax: 5,
          splitNumber: 5,
          min: this.minScore,
          max: this.maxScore,
          splitLine: {
            show: true,
            lineStyle: { type: 'dashed' },
          },
          axisLine: { show: false }, //不显示坐标轴
          axisTick: {
            show: false, //不显示坐标轴刻度线
          },
        },
        series: [
          {
            data: this.showData,
            type: 'bar',
            barWidth: '54px',
            // barCategoryGap:'60px',
            showBackground: true,
            backgroundStyle: {
              color: '#FAFAFA',
            },

            itemStyle: {
              color: '#29A1BB',
              normal: {
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: {
                    //数值样式
                    color: '#29A1BB',
                    fontSize: 16,
                  },
                },
              },
            },
          },
        ],

        grid: {
          top: 0,
          let: 0,
          x: 0,
          y: 0,
          bottom: 1,
          right: 0,
        },
      };
      if (myChart != null && myChart != '' && myChart != undefined) {
        myChart.dispose(); //销毁
      }
      var myChart = echarts.init(document.getElementById('mychart'));
      myChart.setOption(option);
      // 随着屏幕大小调节图表;
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    // NOTE 实时获取柱状图位置
    scrollEvent(e) {
      let BottomValue = e.target.scrollLeft;
      // 绑定  属性名的id
      let name = document.getElementById('item');
      // 绑定  空白遮罩的id
      let shade = document.getElementById('shade');
      let val = BottomValue + 'px';
      name.style.zIndex = 100;
      name.style.paddingLeft = val;
      shade.style.left = val;
    },
    // NOTE 选中数据赋值
    assignmentEcharts(index) {
      // 把数组的下标赋(值给上面的数据
      let arr = this.four_percent[index];
      this.line1 = arr[0];
      this.line2 = arr[1];
      this.line3 = arr[2];
      this.line4 = arr[3];
      this.currentIndex = index;
      this.currentPanel = this.$store.state.lang == 'cn' ? this.$store.state.likeNameList[index] : this.$store.state.likeNameList_en[index];
      let newData = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
      this.forcast_data[index].forEach((item, indexs) => {
        newData[indexs + 1] = Number(item).toFixed(2);
      });
      this.showData = newData;
      if (this.forcast_data[0].some((item) => item < 5)) {
        this.showShade = 0;
        this.maxScore = 5;
        // document.getElementById('Line1').style.top = 288 - (this.four_percent[index][0] / 5) * 239 + 'px';
        console.log((1 - (this.four_percent[index][0] - 1) / 4) * 100, '(this.four_percent[index][0] / 5)');
        // 添加的数据
        document.getElementById('Line1').style.top = (1 - (this.four_percent[index][0] - 1) / 4) * 100 + '%';
        document.getElementById('Line2').style.top = (1 - (this.four_percent[index][1] - 1) / 4) * 100 + '%';
        document.getElementById('Line3').style.top = (1 - (this.four_percent[index][2] - 1) / 4) * 100 + '%';
        document.getElementById('Line4').style.top = (1 - (this.four_percent[index][3] - 1) / 4) * 100 + '%';
      } else if (this.forcast_data[0].some((item) => item > 5 && item < 7)) {
        console.log('7');
        this.showShade = 1;
        document.getElementById('Line1').style.top = (1 - (this.four_percent[index][0] - 1) / 6) * 100 + '%';
        document.getElementById('Line2').style.top = (1 - (this.four_percent[index][1] - 1) / 6) * 100 + '%';
        document.getElementById('Line3').style.top = (1 - (this.four_percent[index][2] - 1) / 6) * 100 + '%';
        document.getElementById('Line4').style.top = (1 - (this.four_percent[index][3] - 1) / 6) * 100 + '%';
      } else {
        this.showShade = 2;
        document.getElementById('Line1').style.top = (1 - (this.four_percent[index][0] - 1) / 9) * 100 + '%';
        document.getElementById('Line2').style.top = (1 - (this.four_percent[index][1] - 1) / 9) * 100 + '%';
        document.getElementById('Line3').style.top = (1 - (this.four_percent[index][2] - 1) / 9) * 100 + '%';
        document.getElementById('Line4').style.top = (1 - (this.four_percent[index][3] - 1) / 9) * 100 + '%';
      }
      this.initEcharts();
    },
    // NOTE 滚动方法
    scrollLeft() {
      this.$refs.rightForm.scrollTop = this.$refs.leftForm.scrollTop;
    },
    scrollRight() {
      this.$refs.leftForm.scrollTop = this.$refs.rightForm.scrollTop;
    },
  },
};
</script>

<style lang="less" scope>
@import url('../../style/History/forecast.less');
</style>
